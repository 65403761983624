import './App.css';
import React, { useEffect, useRef, useState } from 'react';

const text = [];

const history = [];

function App() {
  const [historyText, setHistoryText] = useState('');
  const [commandLine, setCommandLine] = useState([]);
  async function downHandler({ key }) {
    if (key === 'Backspace') {
      text.pop();
      // eslint-disable-next-line no-empty
    } else if (key === 'Shift' || key === 'Meta' || key === 'Escape' || key === 'Control' || key === 'Option') {

      // eslint-disable-next-line no-empty
    } else if (key === 'ArrowUp' || key === 'ArrowDown') {

      // eslint-disable-next-line no-empty
    } else if (key === 'ArrowLeft' || key === 'ArrowRight') {

    } else if (key === 'Enter') {
      const txt = text.reduce((acc, cv) => acc + cv, '');
      if (txt.length === 0) {
        return;
      }
      history.push(txt);
      text.length = 0;
      setHistoryText(history.reduce((acc, cv) => `${acc}  ${cv}\n`, ''));
      try {
        const resp = await fetch('https://www.staticlightlabs.com/terminal', {
          method: 'POST',
          body: JSON.stringify({ cmd: txt }),
        });
        const obj = await resp.json();

        history.push(obj.text);
      } catch (e) {
        history.push('Oh no! There was a communciations _ERRRORRR_');
      }
      setHistoryText(history.reduce((acc, cv) => `${acc}  ${cv}\n`, ''));
    } else {
      text.push(key);
    }
    setCommandLine(text.reduce((acc, cv) => acc + cv, ''));
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [historyText]);

  return (
    <div className="App">
      <pre>
        {historyText}
        &gt;
        {' '}
        {commandLine}
        <span className="blink">█</span>
      </pre>
      <div ref={messagesEndRef} />
    </div>
  );
}

export default App;
